import Vue from "vue";
import VueRouter from "vue-router";
import BlogRoutes from "@/router/blog";

const Home = () => import("@/views/Home.vue");
const Rights = () => import("@/views/Rights.vue");
const Trustable = () => import("@/views/Trustable.vue");
const Faq = () => import("@/views/Faq.vue");
const Error404 = () => import("@/views/Error404.vue");

Vue.use(VueRouter);

const routes = [
  ...BlogRoutes,
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      robots: 'noindex, nofollow',
    },
  },
  {
    path: "/direitos",
    name: "Rights",
    component: Rights,
    meta: {
      robots: 'noindex, nofollow',
    },
  },
  {
    path: "/confiavel",
    name: "Trustable",
    component: Trustable,
    meta: {
      robots: 'noindex, nofollow',
    },
  },
  {
    path: "/perguntasfrequentes",
    name: "Faq",
    component: Faq,
    meta: {
      robots: 'noindex, nofollow',
    },
  },
  {
    path: "/404",
    name: "Error404",
    component: Error404,
    meta: {
      robots: 'noindex, nofollow',
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 100 } };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
