<template>
  <div id="app">
    <NavBar />
    <router-view />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import "bootstrap-4-grid/css/grid.min.css";
import "@/styles/_font.scss";

export default {
  components: {
    NavBar,
  },
  mounted() {
    const meta = document.querySelector('meta[name="robots"]');
    if (this.$route.meta.robots) {
      meta.setAttribute("content", this.$route.meta.robots);
    }
  },
};
</script>

<style lang="scss">
body {
  background: $blue-primary;
  font-family: $font;
  margin: 0;
}
</style>
