import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCarousel from "vue-carousel";
import VueJsonLD from "vue-jsonld";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faAward,
  faUsers,
  faClock,
  faChevronRight,
  faSearch,
  faPlane,
  faFileContract,
  faMobile,
  faUserClock,
  faCoins,
  faGlobeAmericas,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faChevronDown,
  faAward,
  faUsers,
  faClock,
  faChevronRight,
  faPlane,
  faFileContract,
  faMobile,
  faUserClock,
  faCoins,
  faSearch,
  faLinkedinIn,
  faFacebookF,
  faInstagram,
  faTwitter,
  faGlobeAmericas,
);

Vue.use(VueJsonLD);

Vue.use(VueCarousel);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

// Código da diretiva
Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
