export default [
  {
    path: "/post/viajar-com-pouco-dinheiro",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/viajar-com-pouco-dinheiro";
    },
  },
  {
    path: "/post/overbooking-o-que-%C3%A9",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/overbooking-o-que-%C3%A9";
    },
  },
  {
    path: "/post/a-eterna-arte-do-encontro",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/a-eterna-arte-do-encontro";
    },
  },
  {
    path: "/post/lawtech",
    redirect: () => {
      window.location.href = "https://www.blog.liberfly.com.br/post/lawtech";
    },
  },
  {
    path: "/post/semana-da-cultura-nordestina",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/semana-da-cultura-nordestina";
    },
  },
  {
    path: "/post/bagagem-extraviada",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/bagagem-extraviada";
    },
  },
  {
    path: "/post/amazonia-patrimonio-mundial",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/amazonia-patrimonio-mundial";
    },
  },
  {
    path: "/post/mala-para-viagem",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/mala-para-viagem";
    },
  },
  {
    path: "/post/direitos-do-consumidor",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/direitos-do-consumidor";
    },
  },
  {
    path: "/post/viagem-com-crian%C3%A7a",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/viagem-com-crian%C3%A7a";
    },
  },
  {
    path: "/post/remarcar-passagem",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/remarcar-passagem";
    },
  },
  {
    path: "/post/passagens-aereas-baratas-de-madrugada ",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/passagens-aereas-baratas-de-madrugada ";
    },
  },
  {
    path: "/post/dia-mundial-do-turismo",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/dia-mundial-do-turismo";
    },
  },
  {
    path: "/post/dia-da-aviacao",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/dia-da-aviacao";
    },
  },
  {
    path: "/post/viagem-internacional",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/viagem-internacional";
    },
  },
  {
    path: "/post/autorizacao-para-menor-viajar",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/autorizacao-para-menor-viajar";
    },
  },
  {
    path: "/post/viagem-nao-voei",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/viagem-nao-voei";
    },
  },
  {
    path: "/post/shantell",
    redirect: () => {
      window.location.href = "https://www.blog.liberfly.com.br/post/shantell";
    },
  },
  {
    path: "/post/tipo-de-acomodacao",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/tipo-de-acomodacao";
    },
  },
  {
    path: "/post/turismo-europa",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/turismo-europa";
    },
  },
  {
    path: "/post/voo-cancelado-direitos",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/voo-cancelado-direitos";
    },
  },
  {
    path: "/post/_ab2l",
    redirect: () => {
      window.location.href = "https://www.blog.liberfly.com.br/post/_ab2l";
    },
  },
  {
    path: "/post/extravio-de-bagagem-o-que-fazer",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/extravio-de-bagagem-o-que-fazer";
    },
  },
  {
    path: "/post/direito-passageiros",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/direito-passageiros";
    },
  },
  {
    path: "/post/aeroporto-de-lisboa",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/aeroporto-de-lisboa";
    },
  },
  {
    path: "/post/viajar-sozinho",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/viajar-sozinho";
    },
  },
  {
    path: "/post/viajar-com-pet",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/viajar-com-pet";
    },
  },
  {
    path: "/post/atraso-de-voo",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/atraso-de-voo";
    },
  },
  {
    path: "/post/remedios-viagens-internacionais",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/remedios-viagens-internacionais";
    },
  },
  {
    path: "/post/bagagem-de-m%C3%A3o",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/bagagem-de-m%C3%A3o";
    },
  },
  {
    path: "/post/halloween",
    redirect: () => {
      window.location.href = "https://www.blog.liberfly.com.br/post/halloween";
    },
  },
  {
    path: "/post/aeroporto-de-guarulhos",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/aeroporto-de-guarulhos";
    },
  },
  {
    path: "/post/passaporte-internacional",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/passaporte-internacional";
    },
  },
  {
    path: "/post/reembolso-de-passagem-aerea",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/reembolso-de-passagem-aerea";
    },
  },
  {
    path: "/post/milhas",
    redirect: () => {
      window.location.href = "https://www.blog.liberfly.com.br/post/milhas";
    },
  },
  {
    path: "/post/sonhar-com-avi%C3%A3o-caindo",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/sonhar-com-avi%C3%A3o-caindo";
    },
  },
  {
    path: "/post/visto-americano",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/visto-americano";
    },
  },
  {
    path: "/post/rota-romantica-alemanha",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/rota-romantica-alemanha";
    },
  },
  {
    path: "/post/voo-cancelado",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/voo-cancelado";
    },
  },
  {
    path: "/post/gr%C3%A1vida-viajar-de-avi%C3%A3o",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/gr%C3%A1vida-viajar-de-avi%C3%A3o";
    },
  },
  {
    path: "/post/flamengo-libertadores",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/flamengo-libertadores";
    },
  },
  {
    path: "/post/avi%C3%A3o-caindo",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/avi%C3%A3o-caindo";
    },
  },
  {
    path: "/post/furto-de-bagagem",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/furto-de-bagagem";
    },
  },
  {
    path: "/post/musicas-para-viajar",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/musicas-para-viajar";
    },
  },
  {
    path: "/post/regras-de-bagagem",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/regras-de-bagagem";
    },
  },
  {
    path: "/post/regras-da-alfandega",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/regras-da-alfandega";
    },
  },
  {
    path: "/post/filmes-de-viagem",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/filmes-de-viagem";
    },
  },
  {
    path: "/post/pa%C3%ADses-que-n%C3%A3o-precisam-de-passaporte",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/pa%C3%ADses-que-n%C3%A3o-precisam-de-passaporte";
    },
  },
  {
    path: "/post/comissario-de-bordo",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/comissario-de-bordo";
    },
  },
  {
    path: "/post/nordeste-futebol",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/nordeste-futebol";
    },
  },
  {
    path: "/post/bagagem-de-m%C3%A3o-latam",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/bagagem-de-m%C3%A3o-latam";
    },
  },
  {
    path: "/post/reveillon-fortaleza",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/reveillon-fortaleza";
    },
  },
  {
    path: "/post/seguro-viagem",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/seguro-viagem";
    },
  },
  {
    path: "/post/voo-atrasado-direitos",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/voo-atrasado-direitos";
    },
  },
  {
    path: "/post/lua-de-mel",
    redirect: () => {
      window.location.href = "https://www.blog.liberfly.com.br/post/lua-de-mel";
    },
  },
  {
    path: "/post/verao",
    redirect: () => {
      window.location.href = "https://www.blog.liberfly.com.br/post/verao";
    },
  },
  {
    path: "/post/natal",
    redirect: () => {
      window.location.href = "https://www.blog.liberfly.com.br/post/natal";
    },
  },
  {
    path: "/post/transferir-passagens-aereas",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/transferir-passagens-aereas";
    },
  },
  {
    path: "/post/escala-conexao",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/escala-conexao";
    },
  },
  {
    path: "/post/reveillon-2020",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/reveillon-2020";
    },
  },
  {
    path: "/post/o-que-fazer-quando-o-voo-e-cancelado",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/o-que-fazer-quando-o-voo-e-cancelado";
    },
  },
  {
    path: "/post/problemas-de-voo",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/problemas-de-voo";
    },
  },
  {
    path: "/post/asa-delta",
    redirect: () => {
      window.location.href = "https://www.blog.liberfly.com.br/post/asa-delta";
    },
  },
  {
    path: "/post/lei-do-arrependimento-de-passagens-aereas",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/lei-do-arrependimento-de-passagens-aereas";
    },
  },
  {
    path: "/post/comprar-passagem-com-milhas",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/comprar-passagem-com-milhas";
    },
  },
  {
    path: "/post/aviao-desaparecido-no-triangulo-das-bermudas",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/aviao-desaparecido-no-triangulo-das-bermudas";
    },
  },
  {
    path: "/post/reembolso-de-passagem-a%C3%A9rea",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/reembolso-de-passagem-a%C3%A9rea";
    },
  },
  {
    path: "/post/direitos-de-passageiros-em-voo-cancelado",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/direitos-de-passageiros-em-voo-cancelado";
    },
  },
  {
    path: "/post/carnaval-bh",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/carnaval-bh";
    },
  },
  {
    path: "/post/o-que-fazer-quando-se-perde-um-voo ",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/o-que-fazer-quando-se-perde-um-voo ";
    },
  },
  {
    path: "/post/aniversario-de-sao-paulo",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/aniversario-de-sao-paulo";
    },
  },
  {
    path: "/post/carnaval-de-salvador",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/carnaval-de-salvador";
    },
  },
  {
    path: "/post/autorizacao-para-viagem-de-menor",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/autorizacao-para-viagem-de-menor";
    },
  },
  {
    path: "/post/viagem-chances-de-dar-errado",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/viagem-chances-de-dar-errado";
    },
  },
  {
    path: "/post/turbul%C3%AAncia",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/turbul%C3%AAncia";
    },
  },
  {
    path: "/post/destinos-nordeste",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/destinos-nordeste";
    },
  },
  {
    path: "/post/aeroporto-do-gale%C3%A3o",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/aeroporto-do-gale%C3%A3o";
    },
  },
  {
    path: "/post/comida-nordestina",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/comida-nordestina";
    },
  },
  {
    path: "/post/autorizacao-eletronica-uniao-europeia",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/autorizacao-eletronica-uniao-europeia";
    },
  },
  {
    path: "/post/viajar-para-o-chile-dicas",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/viajar-para-o-chile-dicas";
    },
  },
  {
    path: "/post/praias-mais-bonitas-do-brasil",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/praias-mais-bonitas-do-brasil";
    },
  },
  {
    path: "/post/maquiagens-de-carnaval",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/maquiagens-de-carnaval";
    },
  },
  {
    path: "/post/adiantar-passagem",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/adiantar-passagem";
    },
  },
  {
    path: "/post/dicas-para-o-carnaval",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/dicas-para-o-carnaval";
    },
  },
  {
    path: "/post/amor-de-carnaval",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/amor-de-carnaval";
    },
  },
  {
    path: "/post/quarta-feira-de-cinzas",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/quarta-feira-de-cinzas";
    },
  },
  {
    path: "/post/santiago-de-compostela",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/santiago-de-compostela";
    },
  },
  {
    path: "/post/programa-scale-up-endeavor-liberfly",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/programa-scale-up-endeavor-liberfly";
    },
  },
  {
    path: "/post/passaporte-vencido",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/passaporte-vencido";
    },
  },
  {
    path: "/post/dia-internacional-da-mulher",
    redirect: () => {
      window.location.href =
        "https://www.blog.liberfly.com.br/post/dia-internacional-da-mulher";
    },
  },
];
