<template>
  <div id="navbar">
    <div class="container">
      <img class="navbar-logo" src="@/assets/logo-white.png" alt="Logomarca da LiberFly">
      <div class="navbar-links" :class="{opened: opened}">
        <a href="https://reclamacao.liberfly.pt" class="btn btn-action">Enviar Reclamação</a>
        <LanguageSelector class="language-selector" />
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from "@/components/UI/LanguageSelector/LanguageSelector.vue";
export default {
  components: {
    LanguageSelector,
  },
  data() {
    return {
      opened: false,
    };
  },
  beforeRouterUpdate() {
    this.opened = !this.opened;
  },
};
</script>

<style lang="scss" scoped>
#navbar {
  width: 100%;
  height: 60px;
  background: $blue-primary;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  .container {
    display: flex;
  }

  .navbar-logo {
    width: 153px;
    height: 46px;
    display: block;
    margin-right: 15px;
  }

  .navbar-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    .language-selector {
      margin-left: 2rem;
    }
    .btn {
      @media (max-width: 1200px) {
        font-size: 13px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .language {
      width: 50px;
      height: auto;
      display: flex;
      justify-content: center;
      margin: 0 10px;

      img {
        width: 100%;
        height: 100%;
      }

      &:hover {
        transition: 0.2s;
        background: $white;
      }
    }

    a:last-child {
      margin-left: 10px;
    }
  }
}
</style>
